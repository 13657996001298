.account-page {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  .logo {
    margin-bottom: 30px;
    margin-top: -15px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e0e0e0;
  }

  .login-wrapper {
    button {
      width: 100%;
    }
  }

  .login-wrapper,
  .register-wrapper {
    max-width: 460px;
    width: 100%;

    form {
      width: 100%;
    }
  }

  .footer {
    border-top: 1px solid #e0e0e0;
    padding-top: 25px;
    margin-top: 30px;

    >*:last-child {
      margin-bottom: 0;
    }
  }
}