.settings-tabs {
  .nav-item {
    max-width: 100%;

    a {
      border-color: transparent !important;
  
      &:hover, &.active {
        background-color: rgba($color: #000000, $alpha: 0.06);
      }
  
      &.active {
        color: var(--colorPrimary);
      }

      small {
        text-wrap: auto;
        font-size: 0.85rem;
        line-height: 0.8rem;
      }
    }
  }
}