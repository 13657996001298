.orderListItem-thumb {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 55px;
  padding-top: 90%;
  border-radius: 3px;
  background-color: #aaa;
}

.orderListItem-row {
  .orderListItem-actions {
    opacity: 0;
    visibility: hidden;
    transition: all ease .3s;
    background: transparent !important;
  }
  &:hover {
    .orderListItem-actions {
      opacity: 1;
      visibility: visible;
    }
  }
}