.main-header {
  box-shadow: 0 6px 12px -3px rgba($color: #000000, $alpha: 0.15), 0 10px 32px -8px rgba($color: #000000, $alpha: 0.3);
  background-color: #313a46;
  margin-bottom: 35px;

  .navbar-dark {
    .nav-item.dropdown {
      .dropdown-toggle {
        color: white;
        height: 100%;
      }
    }
  }

  .navbar {
    > .container {
      align-items: stretch;
      flex-wrap: nowrap;
    }
  }

  #account-dropdown {
    + .dropdown-menu {
      margin-top: -3px;
      border: none;
      min-width: 110%;
      min-width: calc(100% + 20px);

      .dropdown-item {
        font-size: 1em;
      }
    }
  }

  .navbar-nav  {
    .nav-link {
      padding: 15px 1em 11px !important;
      font-weight: 300;
      font-size: .9em;
      letter-spacing: 1px;
      &.active, &.show {
        color: white;
      }
    }
  }

  .account-dropdown {
    background-color: #404853;


    a.dropdown-toggle {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
      letter-spacing: 0.03em;
    }

    div.dropdown-menu {
      border-top-right-radius: 0;
      background-color: rgba($color: #313a46, $alpha: 0.8);
      backdrop-filter: blur(10px);
      box-shadow: 0 8px 28px -6px rgba(0, 0, 0, 0.15), 0 4px 12px -3px rgba(0, 0, 0, 0.1) !important;

      a.dropdown-item {
        padding: 8px 20px;
        color: #ddd;
        transition: all ease .3s;
        letter-spacing: 0.02em;
        text-shadow: 0 1px 1px rgba($color: #000000, $alpha: 1.0);

        svg {
          margin-right: 0.75em;
        }
        &:hover, &.actived {
          color: #ffffff;
        }
      }
    }
  }
}
